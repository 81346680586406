import React from 'react';
import SEO from '../components/seo';
import Container from '../components/container/container';
import LayoutContent from '../components/layout/layout-content';
import TwoColumnSection from '../components/container/two-column-section';
import SectionImage from '../components/container/section-image';

import { graphql, useStaticQuery } from 'gatsby';
import { IconList } from '../components/iconlist/icon-list';
import { useMedia } from '../hooks/useMedia';
import Cta1to1Meeting from '../components/container/cta-1to1-meeting';

import { InternalLinkButton } from '../components/button/internal-link-button';
import { ClientLogosSection } from '../components/sections/client-logos-section/client-logos-section';
import CtaSection from '../components/container/cta-section';
import OurServiceSection from '../components/sections/our-service-section/our-service-section';
import FactSection from '../components/sections/fact-section/fact-section';
import { HeroContactForm } from '../components/hero-contact-form/hero-contact-form';

import styles from '../styles/index.module.scss';
import { AboutSection } from '../components/sections/about-section/about-section';

const IndexPage = () => {
  const {
    imgHomeWave,
    imgHomeWomen,
    imgHomeData,
    imgHomeExport,
    imgHomeSecurity,
    imgHomeConnect,
    imgHomeDsgvo,
    imgHomeService,
    iconOnlineShop,
    iconBlog,
    iconComment,
    iconRating,
    iconMember,
    iconGallery,
    iconSocialMedia,
    iconAndroidApp,
    iconKooperationsPartner,
    iconIosApp,
    iconProduktkataloge,
    iconAlexa,
  } = useStaticQuery(
    graphql`
      query {
        imgHomeWave: file(relativePath: { eq: "home/home_wave.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 540, maxHeight: 580, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgHomeWomen: file(relativePath: { eq: "home/home_women.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 540, maxHeight: 580, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgHomeData: file(relativePath: { eq: "home/home_data.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, maxHeight: 580, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgHomeExport: file(relativePath: { eq: "home/home_export.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, maxHeight: 580, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgHomeSecurity: file(relativePath: { eq: "home/home_security.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, maxHeight: 580, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgHomeConnect: file(relativePath: { eq: "home/home_connect.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, maxHeight: 580, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgHomeDsgvo: file(relativePath: { eq: "home/home_dsgvo.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        imgHomeService: file(relativePath: { eq: "home/home_service.png" }) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        iconOnlineShop: file(relativePath: { eq: "icons/online_shop_icon.svg" }) {
          publicURL
          extension
        }
        iconBlog: file(relativePath: { eq: "icons/blog_icon.svg" }) {
          publicURL
          extension
        }
        iconComment: file(relativePath: { eq: "icons/kommentar_icon.svg" }) {
          publicURL
          extension
        }
        iconRating: file(relativePath: { eq: "icons/bewertungssystem_icon.svg" }) {
          publicURL
          extension
        }
        iconMember: file(relativePath: { eq: "icons/bewertungssystem_icon.svg" }) {
          publicURL
          extension
        }
        iconGallery: file(relativePath: { eq: "icons/mediagalerie_icon.svg" }) {
          publicURL
          extension
        }
        iconSocialMedia: file(relativePath: { eq: "icons/social_meia_icon.svg" }) {
          publicURL
          extension
        }
        iconAndroidApp: file(relativePath: { eq: "icons/android-app.svg" }) {
          publicURL
          extension
        }
        iconKooperationsPartner: file(relativePath: { eq: "icons/kooperationspartner.svg" }) {
          publicURL
          extension
        }
        iconIosApp: file(relativePath: { eq: "icons/ios-app.svg" }) {
          publicURL
          extension
        }
        iconProduktkataloge: file(relativePath: { eq: "icons/produktkataloge.svg" }) {
          publicURL
          extension
        }
        iconAlexa: file(relativePath: { eq: "icons/alexa.svg" }) {
          publicURL
          extension
        }
      }
    `
  );

  const firstSetIcons = [
    { asset: iconOnlineShop, text: 'Online-Shop', altText: 'Online-shop' },
    { asset: iconBlog, text: 'Blog', altText: 'Blog' },
    { asset: iconComment, text: 'Kommentarfunktionen', altText: 'Kommentar' },
    { asset: iconRating, text: 'Bewertungssystem', altText: 'Bewertung' },
    { asset: iconMember, text: 'Mitgliederbereich', altText: 'Mitgliederbereich' },
    { asset: iconGallery, text: 'Mediagalerie', altText: 'Mediagalerie' },
    { asset: iconSocialMedia, text: 'Anbindung an Social Media', altText: 'Anbindung an Social Media' },
  ];

  const secondSetIcons = [
    { asset: iconAndroidApp, text: 'Android-App', altText: 'Android-App' },
    { asset: iconKooperationsPartner, text: 'Kooperationspartner', altText: 'Kooperationspartner' },
    { asset: iconIosApp, text: 'iOS-App', altText: 'Ios-App' },
    { asset: iconProduktkataloge, text: 'Produktkataloge', altText: 'Produktkataloge' },
    { asset: iconAlexa, text: 'Alexa', altText: 'Alexa' },
  ];

  const isIPad = useMedia(['(min-width: 768px) and (max-width: 991px)'], [true], false);

  return (
    <LayoutContent showTransparentBackground noMargin>
      <SEO title="Professionelle und moderne Webseiten" bodyClass={styles.body} />

      <HeroContactForm page="home" showHeader={false} />

      <AboutSection />

      <ClientLogosSection />
      <FactSection />

      <OurServiceSection />

      <Container>
        <Cta1to1Meeting
          link="/projektanfrage/#inquiry-header"
          text="Jetzt kostenlose Projektanfrage stellen!"
          btnText="Projektanfrage stellen"
          variant="ctaButtonColor"
        />
      </Container>

      <Container className={styles.benefitsTitle}>
        <a href="#investitionssicherheit">
          <h4>Vorteile der JAMstack Webseiten bei Evelan</h4>
        </a>
      </Container>
      <TwoColumnSection variant="fix" id="investitionssicherheit" className={styles.firstSection}>
        <div>
          <h2>{isIPad ? 'Investitions- & Planungs-sicherheit' : 'Investitions- & Planungssicherheit'}</h2>
          <p className="highlight">Bis zu 70% Entwicklungskosten innerhalb von 5 Jahren einsparen!</p>
          <p>
            Das Web-Design wird vom Web-Content komplett unabhängig aufgesetzt. Alle Anpassungen können somit deutlich
            kosteneffizienter realisiert und neue Funktionalitäten schnell und unkompliziert implementiert werden.
          </p>
          <p>Jede beauftragte Webanwendung ist in einem stabilen Kostenplan abgebildet. Dazu gehören zum Beispiel:</p>

          <IconList items={firstSetIcons} />
        </div>
        <div>
          <SectionImage className={styles.image} fluid={imgHomeWave.childImageSharp.fluid} alt="Die Welle" />

          <p>
            Eine herkömmliche, rein informative Produktseite wird so “im Handumdrehen” zum voll funktionsfähigen
            Onlineshop mit Bewertungssystem. Viele weitere innovative Funktionen lassen ganz nach Bedarf Ihre Online
            Präsenz zu einem modernen Unternehmensauftritt werden.
          </p>
          <p>
            Durch die Integration eines Shopify® oder digistore®-Systems sind alle üblichen Funktionalitäten und
            Prozesse abgebildet, inklusive einer CMS-Struktur, die im Hintergrund benutzerfreundlich, einfach und
            kostensparend selbst verwaltet werden kann. Es lassen sich auch andere Systeme problemlos anbinden, sofern
            diese über eine technische Schnittstelle (API) verfügen. Das könnte Ihr CRM System oder auch ein Newsletter
            Tool sein.
          </p>
          <p>
            So kann auch der gesamte Content der Webseiten selbst gepflegt und betreut werden - Texte, Bilder & Videos
            in Eigenregie neu eingetragen oder überarbeitet werden. Dies spart zusätzliche Kosten für Layoutanpassungen
            und Aktualisierungen.
          </p>
          <p>
            Möglich macht dies eine innovative Cloud-Technologie, bei der mit einem so genannten Headless CMS der
            Webseiten-Inhalt vom Design separiert gespeichert wird.
          </p>
        </div>
      </TwoColumnSection>

      <TwoColumnSection variant="reverse">
        <div>
          <h2>Höhere Sichtbarkeit durch super schnelle Ladezeiten</h2>
          <p className="highlight">Mehr Traffic durch optimierte Ladegeschwindigkeiten!</p>
          <p>
            Auch bei schlechten Mobilfunkverbindungen wird alles technisch machbare ausgenutzt, um die
            Benutzeroberfläche schnellstmöglich zu laden und darzustellen. So werden grafische Elemente bei langsamen
            Verbindung automatisch in kleinere Formate umgerechnet.
          </p>
          <p>
            Gleichzeitig verbindet sich das mobile Endgerät immer mit dem geographisch am nächstgelegenen Rechenzentrum.
          </p>
          <p>
            Auch laden sich Unterseiten im Hintergrund vor, während der Benutzer noch die aktuelle Seite betrachtet.
            Beim Klick auf den entsprechenden Link ist die nächste Webseite bereits heruntergeladen und erscheint sofort
            ohne jegliche Verzögerung.
          </p>
        </div>
        <div>
          <SectionImage
            className={styles.image}
            fluid={imgHomeWomen.childImageSharp.fluid}
            alt="Eine Frau schaut auf ein Laptop"
          />
        </div>
      </TwoColumnSection>

      <CtaSection text="Stellen Sie jetzt Ihre kostenfreie Projektanfrage!">
        <InternalLinkButton to="/projektanfrage/#inquiry-header" className={styles.ctaButton} color="highlight">
          Projektanfrage stellen
        </InternalLinkButton>
      </CtaSection>

      <TwoColumnSection>
        <div>
          <h2>Hohe Besucherzahlen leicht bewältigen</h2>
          <p className="highlight">Nie mehr überlastete Server durch flexible Kapazitäten und redundante Hardware!</p>
          <p>
            Auch bei sehr hohen und dynamischen Besucherzahlen bleiben alle Webseiten in gewohnter Qualität abrufbar.
            Alle Online-Angebote stehen jederzeit zur Verfügung, auch wenn die Konkurrenz vielleicht schon längst
            hoffnungslos überlastet ist.
          </p>
          <p>
            Kommt es an einem Standort oder an einer Serverkomponente zu einem Ausfall, wird der Web-Besucher direkt
            umgeleitet.
          </p>
          <p>
            Erfolgreiche Marketingaktionen, die hohe und auch unerwartete Besucherzahlen generieren werden problemlos
            bewältigt. Jeder Aufwand für die Abstimmung und Vorbereitung der Server durch den Hoster entfällt.
          </p>
          <p>
            Sogar bei Naturkatastrophen und Stromausfällen ist eine Ausfallsicherheit gewährleistet, da die
            cloudbasierte Serverstruktur auf unabhängige Standorte verteilt ist.
          </p>
        </div>
        <div>
          <SectionImage fluid={imgHomeData.childImageSharp.fluid} alt="Monitor mit den Daten-Diagrammen" />
        </div>
      </TwoColumnSection>

      <TwoColumnSection variant="reverse">
        <div>
          <h2>Einfache Anbindung</h2>
          <p className="highlight">Für alle digitalen Herausforderungen der Zukunft gerüstet! </p>
          <p>
            Durch eine Daten-API nach neuesten Standards werden alle relevanten Informationen aus dem CMS direkt an
            externe Kanäle ausgespielt oder von diesen abgerufen.
          </p>
          <IconList items={secondSetIcons} />
          <p>
            Dies sind nur einige Anwendungsbeispiele, mehr sind auf Anfrage erhältlich. Die Integration benötigt keinen
            großen Programmieraufwand im Backend und spart damit wiederum Kosten.
          </p>
        </div>
        <div>
          <SectionImage
            fluid={imgHomeConnect.childImageSharp.fluid}
            alt="Zwei Smartphones werden an einander gehalten"
          />
        </div>
      </TwoColumnSection>

      <TwoColumnSection>
        <div>
          <h2>Exportfunktion für Content</h2>
          <p className="highlight">Daten der gesamten Webseite inkl. Blogbeiträgen exportieren!</p>
          <p>
            Mit den JAMstack Webseiten von Evelan extrahieren Sie sich auf Wunsch alle von Ihnen eingegebene Daten in
            ein maschinell lesbares Format. Damit sind Sie alleiniger “Herrscher” über Ihre Daten und unabhängig von
            anderen Dienstleistern.
          </p>
        </div>
        <div>
          <SectionImage
            fluid={imgHomeExport.childImageSharp.fluid}
            alt="Zwei Arbeitskollegen schauen gemeinsam auf den Laptop"
          />
        </div>
      </TwoColumnSection>

      <TwoColumnSection variant="reverse">
        <div>
          <h2>Daten- und Einbruchsicherheit</h2>
          <p className="highlight">Immer auf dem neuesten Stand. Wir kümmern uns um alle Updates!</p>
          <p>KI gestützter Schutz gegen Hacker-Angriffe im Premium-Tarif.</p>
          <p>
            Durch automatisierte Updates und anerkannten Sicherheitszertifikaten weisen wir nicht nur höchsten und
            aktuellsten Standards nach, sondern steigern auch indirekt das Ansehen bei allen Webseiten-Besuchern.
            Tägliche Scans sorgen für saubere Webseiten.
          </p>
        </div>
        <div>
          <SectionImage fluid={imgHomeSecurity.childImageSharp.fluid} alt="Arbeitsplatz mit dem Laptop" />
        </div>
      </TwoColumnSection>

      <TwoColumnSection variant="simple">
        <div>
          <SectionImage fluid={imgHomeDsgvo.childImageSharp.fluid} alt="Ein Smartphone mit dem Fingerabdruckscanner" />
          <h2>DSGVO Konform</h2>
          <ul>
            <li>
              Besucher-Statistiken ganz ohne Google Analytics
              <ul>
                <li>Alle Kundendaten bleiben in Ihrer Obhut</li>
                <li>Zuverlässige Serverseitige Statistiken mit besserem Kundenerlebnis</li>
              </ul>
            </li>
            <li>Impressum Generator inkl.</li>
            <li>Datenschutzerklärung Generator inkl.</li>
            <li>DSGVO-konforme E-Mail Signatur inkl.</li>
            <li>Cookie-Management</li>
            <li>DSGVO Report von eRecht-24</li>
          </ul>
        </div>
        <div>
          <SectionImage fluid={imgHomeService.childImageSharp.fluid} alt="Tippen auf der Tastatur" />
          <h2>Service &amp; Support in Germany</h2>
          <ul>
            <li>Wir bieten einen direkten Ansprechpartner für alle Belange</li>
            <li>Kommunikation gerne per E-Mail, Telefon und Videokonferenz oder auch per WhatsApp</li>
          </ul>
        </div>
      </TwoColumnSection>

      <Cta1to1Meeting
        text="Machen Sie mit uns gemeinsam den ersten Schritt in eine sichere und schnellere Zukunft Ihrer Webseite und stellen Sie noch heute eine kostenlose Projektanfrage"
        link="/projektanfrage/#inquiry-header"
        btnText="Projektanfrage stellen!"
      />
    </LayoutContent>
  );
};

export default IndexPage;
