import React from 'react';
import Container from '../../container/container';
import styles from './fact-section.module.scss';

const contentList = [
  {
    title: '100%',
    description: 'Zufriedenheitsgarantie',
    subDescription: 'Wir entwickeln, bis Sie zufrieden sind',
  },
  {
    title: '100%',
    description: 'Erfolg',
    subDescription: 'Ihre Kunden werden Ihre Webseite lieben',
  },
  {
    title: `Über ${new Date().getFullYear() - 2003}`,
    description: 'Jahre',
    subDescription: 'Expertise und Know-how',
  },
];
const FactSection = () => {
  return (
    <div className={styles.factWrapper}>
      <Container>
        <div className={styles.titleHolder}>
          <h1 className={styles.title}>100% Sicher!</h1>
        </div>
        <div className={styles.contentHolder}>
          {contentList.map((item, key) => (
            <div key={key} className={styles.contentItem}>
              <p className={styles.contentTitle}>{item.title}</p>
              <p className={styles.contentDescription}>{item.description}</p>
              <p className={styles.contentSubdescription}>{item.subDescription}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default FactSection;
